<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>

      <div class="content-body">
        <b-overlay :show="pageOverlayEnabled">
          <transition :name="routerTransition" mode="out-in">
            <slot />
          </transition>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { commonStore } from '@/store'

export default {
  components: {
    AppBreadcrumb,
  },
  computed: {
    pageOverlayEnabled() {
      return commonStore.pageOverlayEnabled
    },
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth,
    }
  },
}
</script>

<style>
</style>
