<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ new Date().getFullYear() }} ©
      <b-link class="ml-25" href="https://wayup.in/" target="_blank">
        WAYUP
      </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
