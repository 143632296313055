import Flag from '@/components/controls/form/Flag'
import { i18nStore } from '@/store'
import { Language } from '@/store/i18nStore'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

type LangItem = {
  language: Language
  title: string
}

@Component
export default class LanguageSelector extends tsx.Component<{}> {
  items: LangItem[] = [
    {
      language: 'ru',
      title: 'Русский',
    },
    {
      language: 'uk',
      title: 'Украинский',
    },
    {
      language: 'en',
      title: 'Английский',
    },
  ]

  get selectedLanguage() {
    return this.items.find(
      item => item.language === i18nStore.selectedLanguage,
    )!
  }

  selectLanguage(item: LangItem) {
    i18nStore.selectedLanguage = item.language
  }

  protected render() {
    return (
      <b-dropdown
        variant="flat-dark"
        scopedSlots={{
          'button-content': () => (
            <div class="d-flex align-items-center">
              <Flag language={this.selectedLanguage.language} class="mr-25" />
              {this.selectedLanguage.title}
            </div>
          ),
        }}
      >
        {this.items.map(item => (
          <b-dropdown-item onClick={() => this.selectLanguage(item)}>
            <div class="d-flex align-items-center">
              <Flag language={item.language} class="mr-25" />
              {item.title}
            </div>
          </b-dropdown-item>
        ))}
      </b-dropdown>
    )
  }
}
