export default [
  { header: 'Пользователи' },
  {
    title: 'Администраторы',
    route: 'admins',
    icon: 'UsersIcon',
    permission: 'admin.administrators',
  },
  {
    title: 'Пoльзователи',
    route: 'allStudents',
    icon: 'UsersIcon',
    permission: 'admin.students',
  },
  {
    title: 'Студенты',
    route: 'orders',
    icon: 'UsersIcon',
    permission: 'admin.students',
  },
  {
    title: 'Партнёрка',
    route: 'partners',
    icon: 'BriefcaseIcon',
    permission: 'admin.partners',
  },
  { header: 'Образование' },
  {
    title: 'Курсы',
    route: 'courses',
    icon: 'BookIcon',
    permission: 'admin.courses',
  },
  {
    title: 'Проверка ДЗ',
    route: 'homework',
    icon: 'MailIcon',
    permission: 'admin.homework',
  },
  {
    title: 'Аналитика',
    route: 'analytics',
    icon: 'TrendingUpIcon',
    permission: 'admin.analytics',
  },
  {
    title: 'События',
    route: 'webinars',
    icon: 'TvIcon',
    permission: 'admin.webinars',
  },
  { header: 'Оплата' },
  {
    title: 'Цены и акции',
    route: 'prices',
    icon: 'DollarSignIcon',
    permission: 'admin.promotions',
  },
  {
    title: 'Промокоды',
    route: 'promocodes',
    icon: 'PercentIcon',
    permission: 'admin.coupons',
  },
  {
    title: 'Платежи',
    route: 'payments',
    icon: 'BookIcon',
    permission: 'admin.payments',
  },
  {
    title: 'Расчет ЗП',
    route: 'salary',
    icon: 'BookOpenIcon',
    permission: 'admin.salary',
  },
  { header: 'Контент' },
  {
    title: 'Журнал',
    route: 'blog',
    icon: 'BookOpenIcon',
    permission: 'admin.blog',
  },
]
