<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <LanguageSelector class="mx-1" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.first_name }} {{ user.last_name }}
            </p>
            <span class="user-status">{{ user.group.title }}</span>
          </div>
          <div>
            <b-avatar
              size="40"
              variant="light-primary"
              :src="user.avatar_url"
              :text="avatarText"
            />
            <b-badge
              v-if="isDev"
              variant="warning"
              class="badge-glow"
              :style="{
                position: 'absolute',
                top: '-10px',
                right: '-12px',
                zIndex: 100,
              }"
            >
              DEV
            </b-badge>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'userSettings' }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Профиль</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Выход</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { avatarText } from '@core/utils/filter'
import { userStore } from '@/store'
import { logout } from '@/@core/auth/auth'
import LanguageSelector from '@/components/navbar/LanguageSelector'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    LanguageSelector,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isDev() {
      return process.env.VUE_APP_AXIOS_URL.includes('stage')
    },
    user() {
      return userStore.user
    },
    avatarText() {
      return avatarText(`${this.user.first_name} ${this.user.last_name}`)
    },
  },
  methods: {
    logout() {
      logout()
    },
  },
}
</script>
